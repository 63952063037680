/*
 * 업무구분: 수익
 * 파 일 명: FSCustInfo.vue
 * 파일설명: 고객정보 영역 (공통)
 * 접근권한: 
 * 작 성 일: 2023.04.20
 * 작 성 자: 이태흥
 */
<template>

  <ur-box-container direction="column" alignV="start">

    <!-- Accordion 아코디언 -->
    <div class="wrap-accordion" :class="{active: isActive}">
      <div class="wrap-table-title">
        <h2 class="table-title flex-sb mr0" @click="fn_IsActive">
          고객정보
          <mo-button class="btn-title-accordion"></mo-button>
        </h2>
      </div>
      <div class="wrap-table accordion accordion01 wrap-table-gray">
        <table class="table col-type th-wsN th10p">

          <tbody>
            <tr>
              <th> 고객명 </th>
              <td> {{ lv_cust_info.fnd_cust_nm }} </td>

              <th> 실명번호 </th>
              <td> {{ lv_cust_info.rlnm_cnf_no_encr }} </td>

              <th> 핸드폰번호 </th>
              <td> {{ lv_cust_info.phone_no }} </td>
            </tr>
            <tr>
              <th> 투자성향 </th>
              <td> {{ lv_cust_info.invst_dispos }}<br /><span class="small">{{ lv_cust_info.expd_stnd_ymd }}</span></td>

              <th> 집전화 </th>
              <td> {{ lv_cust_info.hom_no }} </td>

              <th> 회사전화 </th>
              <td> {{ lv_cust_info.job_no }} </td>
            </tr>
            <tr>
              <th> 대리인 성명 </th>
              <td> {{ lv_cust_info.agent_nm }} </td>
              
              <th> 대리인 회사전화 </th>
              <td> {{ lv_cust_info.agent_job_no }} </td>

              <th> 대리인 핸드폰번호 </th>
              <td> {{ lv_cust_info.agent_phone_no }} </td>
            </tr>
            <tr>
              <th> 본인과의 관계 </th>
              <td> {{ lv_cust_info.agent_rel_gb_nm }} </td>

              <th> 대리인 유효기간 </th>
              <td> {{ lv_cust_info.asgn_ymd }} </td>

              <th> </th>
              <td> </td>
            </tr>
            <tr>
              <th> 특이사항 </th>
              <td> {{ lv_cust_info.cust_bas_note }} </td>
              <th> </th>
              <td> </td>
              <th> </th>
              <td> </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
    <!-- //Accordion 아코디언 -->

  </ur-box-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'  
  import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'


  export default {
    name: 'FSCustInfo',
    props: {
      propObj: {type:Object, default:null},
    },
    components: {

    },
    data() {
      return {

        // 고객정보
        lv_cust_info: {},

        // 고객정보 펼치기/접기
        isActive: false,

      }
    },
    computed: {

    },
    mounted () {

    },
    methods: {

      /******************************************************************************
       * Function명 : fn_IsActive
       * 설명       : 고객정보 펼치기/접기
       ******************************************************************************/
      fn_IsActive() {
        this.isActive = !this.isActive
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[FSCustInfo] fn_Init...')

        // 고객정보
        this.lv_cust_info = {}

      },


      /******************************************************************************
       * Function명 : fn_CustInfoSearch, fn_CustInfoSearchResult
       * 설명       : 고객정보조회
       ******************************************************************************/
      fn_CustInfoSearch() {
        console.log('fn_CustInfoSearch.....')

        let t_data = {bfcer_cust_no : this.propObj.custNo}
        FSInfoUtil.commInfo(this, 0, t_data, this.fn_CustInfoSearchResult)
      },

      fn_CustInfoSearchResult(pResultData) {
        console.log('fn_CustInfoSearchResult.....')

        // 초기화
        this.fn_Init()

        let t_data = pResultData.data.sy001000_O_00VO
        
        console.log(t_data)

        if( t_data.length > 0) {
          this.lv_cust_info                   = t_data[0]

          // 고객명 세팅
          this.$emit('fs-cust-name', t_data[0].fnd_cust_nm)

          // 실명번호
          this.lv_cust_info.rlnm_cnf_no_encr  = FSCommUtil.gfn_substr(t_data[0].rlnm_cnf_no_encr, 0, 6) + '-' + FSCommUtil.gfn_substr(t_data[0].rlnm_cnf_no_encr, 6, 7) 

          // 핸드폰번호
          this.lv_cust_info.phone_no          = FSCommUtil.gfn_isNull(t_data[0].bman_celph_dstno_encr) ? '' : t_data[0].bman_celph_dstno_encr + '-' + t_data[0].bman_celph_tlnum_no_encr + '-' + t_data[0].bman_celph_bkno_sno_encr
          
          // 투자성향
          this.lv_cust_info.invst_dispos      = t_data[0].invst_dispos                                                                                                  
          this.lv_cust_info.expd_stnd_ymd     = '(' + t_data[0].expd_stnd_ymd + '~' + t_data[0].expd_exp_ymd + ')'

          // 집전화
          this.lv_cust_info.hom_no            = FSCommUtil.gfn_isNull(t_data[0].hom_dstno_encr) ? '' : t_data[0].hom_dstno_encr + '-' + t_data[0].hom_tlnum_no_encr + '-' + t_data[0].hom_bkno_sno_encr

          // 회사전화
          this.lv_cust_info.job_no            = FSCommUtil.gfn_isNull(t_data[0].jobp_dstno_encr) ? '' : t_data[0].jobp_dstno_encr + '-' + t_data[0].jobp_tlnum_no_encr + '-' + t_data[0].jobp_bkno_sno_encr


          // 대리인 성명
          this.lv_cust_info.agent_nm            = t_data[0].agent_nm

          // 대리인 회사전화
          this.lv_cust_info.agent_job_no        = FSCommUtil.gfn_isNull(t_data[0].agnt_jobp_dstno_encr) ? '' : t_data[0].agnt_jobp_dstno_encr + '-' + t_data[0].agnt_jobp_tlnum_no_encr + '-' + t_data[0].agnt_jobp_bkno_sno_encr

          // 대리인 핸드폰번호(확인불가)
          // this.lv_cust_info.agent_phone_no      = FSCommUtil.gfn_isNull(t_data[0].agnt_hom_dstno_encr) ? '' : t_data[0].agnt_hom_dstno_encr + '-' + t_data[0].agnt_celph_tlnum_no_encr + '-' + t_data[0].agnt_celph_bkno_sno_encr

          // 본인과의 관계
          this.lv_cust_info.agent_rel_gb_nm     = t_data[0].agent_rel_gb_nm

          // 대리인 유효기간
          this.lv_cust_info.asgn_ymd            = FSCommUtil.gfn_isNull(t_data[0].asgn_ymd) ? '' : t_data[0].asgn_ymd + ' ~ ' + t_data[0].exp_ymd

          // 특이사항
          this.lv_cust_info.cust_bas_note       = t_data[0].cust_bas_note

          this.$emit('fs-cust-info', t_data[0])
        }
      },


    }
  };
</script>